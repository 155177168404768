export function setStoredItem(key: string, token: string) {
  // Use localStorage, sessionStorage or Cookies to store the data
  localStorage.setItem(key, token);
}

export function getStoredItem(key: string): string | null {
  // Read the data in localStorage, sessionStorage or Cookies
  return localStorage.getItem(key) || null;
}

export function removeStoredItem(key: string | string[]) {
  // Remove the date from localStorage, sessionStorage or Cookies
  if (Array.isArray(key)) {
    key.forEach((k) => localStorage.removeItem(k));
  } else {
    localStorage.removeItem(key);
  }
}
