const APIConfigs = {
  /** BASE URL for All APIs */
  API_BASE_URL: `${process.env.REACT_APP_API_BASE_URL}`,

  /** Authentication APIs */
  Authentication: {
    LOGIN: "/customer-login",
    // REFRESH_TOKEN: "/refresh-token",
    BUSINESS_PROFILE: "/user_profile",
    LOGOUT: "/logout",
  },

  /** Forgot Password APIs */
  ForgotPassword: {
    FORGOT_PASSWORD: "/send_pincode",
    VERIFY_CODE: "/verify_pincode",
    RESET_PASSWORD: "/reset_password",
  },

  /** Members APIs */
  Members: {
    BULK_UPDATE_MEMBERS: "/bulk-update-member",
    CREATE_MEMBERS_BY_EMAILS: "/members/emails",
    CREATE_MEMBERS_BY_CSV: "/members/csv",
    RUD_MEMBERS: "/members",
    UPDATE_A_MEMBER: "/members/:id",
    READ_MEMBER_COLOR_SETTINGS: "/colors/:memberId",
    UPDATE_MEMBER_COLOR_SETTINGS: "/update-colors",
  },

  /** Links APIs */
  Links: {
    READ_LINK_TYPES: "/members/links/types",
    CREATE_MEMBERS_LINK: "/members/links",
    READ_MEMBER_LINKS: "/members/links",
    UPDATE_MEMBER_LINKS: "/members/:memberId/links",
    CREATE_MEMBER_LINK: "/members/:memberId/links",
    UPDATE_MEMBER_LINK: "/members/:memberId/links/:linkId",
    DELETE_MEMBER_LINK: "/members/:memberId/links/:linkId/:globalId",
  },

  /** Devices */
  Devices: {
    READ_DEVICES: "/devices",
    UNMAP_DEVICES: "/devices/unmap",
  },

  /** Templates APIs */
  Templates: {
    CREATE_READ_TEMPLATES: "/templates",
    UPDATE_DELETE_TEMPLATE: "/templates/:id",
    SET_AS_DEFAULT_TEMPLATE: "/templates/:id/set-as-default",
    ASSIGN_MEMBERS: "/templates/:id/assign",
    REORDER_TEMPLATE_LINKS: "/templates/:id/links/reorder",
    CREATE_TEMPLATE_LINK: "/templates/:id/links",
    UPDATE_DELETE_TEMPLATE_LINK: "/templates/:templateId/links/:linkId",
  },

  /** Member's HashTags API */
  MemberHashTags: {
    CREATE_READ_DELETE_HASHTAGS: "/members/hashtags",
    UPDATE_HASHTAG: "/members/hashtags/:id",
  },

  /** Integrations API */
  Integrations: {
    READ_IPLATFORMS: "/integrations/platforms",
    READ_INTEGRATIONS: "/integrations",
    UPATE_DELETE_INTEGRATION: "/integrations/:id",
    CREATE_INTEGRATION_HUBSPOT: "/integrations/hubspot",
    CREATE_INTEGRATION_ACTIVE_DIRECTORY: "/integrations/azure-ad",
    CREATE_INTEGRATION_SALESFORCE: "/integrations/salesforce",
    CREATE_INTEGRATION_DYNAMICS: "/integrations/dynamics",
    READ_SYNCED_MEMBERS: "/integrations/synced-members",
    UPDATE_SYNCED_MEMBERS: "/integrations/:id/synced-members",
  },

  /** Active Directory APIs */
  ActiveDirectory: {
    READ_AD_GROUPS_USERS: "/integrations/:id/ad-groups-users",
    SYNC_AD_USERS: "/integrations/:id/sync",
    KEEP_MISSING_USERS: "/integrations/:id/keep-missing-users",
  },

  /** Contact Card APIs */
  ContactCard: {
    ADD_TEMPLATE_LINKS: "/templates/:templateId/buttons",
    ADD_MEMBER_LINKS: "/members/:memberId/buttons",
  },

  /** Settings APIs */
  Settings: {
    READ_SETTINGS: "/settings",
    UPDATE_MEMBERS_ROLE: "/settings/members/roles",
  },

  /** Server Logs API */
  ServerLogs: {
    CREATE_SERVER_LOG: "/log",
  },
};

export default APIConfigs;
