import type {
  CreateServerLogAPIResponse,
  CreateServerLogResponse,
} from "./serverLogsTypes";

export function transformCreateServerLogResponse(
  response: CreateServerLogAPIResponse
): CreateServerLogResponse {
  return {
    success: response.success,
    message: response.message,
  };
}
