import { createApi } from "@reduxjs/toolkit/query/react";
import APIConfigs from "../../configs/apiConfigs";
import { baseQueryWithReauth } from "../rtk-query/baseQuery";
import type {
  CreateServerLogRequestArgs,
  CreateServerLogResponse,
} from "./serverLogsTypes";
import { transformCreateServerLogResponse } from "./serverLogsHelpers";

const serverLogsService = createApi({
  reducerPath: "serverLogsService",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    createServerLog: builder.mutation<
      CreateServerLogResponse,
      CreateServerLogRequestArgs
    >({
      query: (args) => ({
        url: APIConfigs.ServerLogs.CREATE_SERVER_LOG,
        method: "POST",
        body: args,
      }),
      transformResponse: transformCreateServerLogResponse,
    }),
  }),
});

export default serverLogsService;
export const { useCreateServerLogMutation } = serverLogsService;
